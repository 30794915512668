import React from "react"
import strechedHeroStyles from "./strechedHero.module.scss"
import imgHero from "../../images/heroStudy.png"

export default function strechedHero(props) {
  return (
    <div className={strechedHeroStyles.container}>
      <div className={strechedHeroStyles.imgContainer}>
        <img src={imgHero} className={strechedHeroStyles.img} alt="heroStudy" />
      </div>
      <div className={strechedHeroStyles.textContainer}>
        <h1 className={strechedHeroStyles.title}>{props.title}</h1>
        <hr className={strechedHeroStyles.hr}></hr>
        <p className={strechedHeroStyles.text}>{props.text}</p>
      </div>
    </div>
  )
}
