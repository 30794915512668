import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import blogTemplateStyles from "./blogTemplate.module.scss"
import SEO from "../components/seo"
import BlogTemplate from "../components/tips-para-estudiar/publicationsTemplate"
import Layout from '../components/layout/layout'

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description
      publishedDate(formatString: "MMM Do, YYYY")
      body {
        json
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img className={blogTemplateStyles.img} alt={alt} src={url} />
      },
    },
  }

  const findSeoImage = props.data.contentfulBlogPost.body.json.content.find(
    function(node) {
      return node.nodeType === "embedded-asset-block"
    }
  )
  const seoImage = findSeoImage.data.target.fields.file["en-US"].url

  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <SEO
        title={props.data.contentfulBlogPost.title}
        url={`/publicaciones/${props.data.contentfulBlogPost.slug}/`}
        image={`https:${seoImage}`}
        description={props.data.contentfulBlogPost.description}
      />

      <BlogTemplate
        title={props.data.contentfulBlogPost.title}
        text={documentToReactComponents(
          props.data.contentfulBlogPost.body.json,
          options
        )}
      />
    </Layout>
  )
}

export default Blog
